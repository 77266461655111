@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/switzer');

body {
  margin: 0;
  font-family: "Switzer", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button.numeric-input-without-arrows,
input::-webkit-inner-spin-button.numeric-input-without-arrows {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].numeric-input-without-arrows {
  -moz-appearance: textfield;
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

.link {
  @apply text-orange-500 hover:underline hover:underline-offset-2
}

.grecaptcha-badge { visibility: hidden; }

[type='text']:focus {
  box-shadow: none;
}